import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import "swiper/css/swiper.css"
import useMediaQuery from "../../hooks/useMediaQuery"

const ItConsulting = () => {
  const isDesktop = useMediaQuery("(min-width: 768px)")

  return (
    <section
      id="it-consulting"
      className="it-consulting"
      style={{ padding: "1.5rem 0" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-8 mx-auto text-center">
            <h2 className="my-3 font-weight-medium">
              Turn Low-Code Into High-Impact With a TrackVia Partner
            </h2>
            <p className="mx-auto" style={{ maxWidth: "825px" }}>
              Whatever your need: custom tools, integrations, workflow
              automation, or cleaner reporting, we improve TrackVia’s impact on
              your business.
            </p>
          </div>
        </div>
        {isDesktop ? (
          <LazyLoadComponent>
            <div class="tabs pt-4">
              <input
                type="radio"
                class="tabs__radio"
                name="tabs-example"
                id="tab1"
                defaultChecked
              />
              <label
                for="tab1"
                class="tabs__label mx-auto"
                style={{ width: "30%", textAlign: "center" }}
              >
                Live in Weeks
              </label>
              <div class="tabs__content">
                <div
                  className="row align-items-center mt-3 mb-5"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
                >
                  <div className="col-lg-6 col-md-6 mt-4 mb-4">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/9689891d27376c47bfe1c37636405b14a1f08b22-6720x4480.jpg"
                      alt="Live in Weeks"
                      layout="constrained"
                      style={{ height: "20rem" }}
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 text-lg-left text-md-left text-center align-self-center mb-lg-0 mb-md-0 mb-5 pl-md-5">
                    <h3 className="text-left-mobile">Live in Weeks</h3>
                    <p className="my-4 text-left-mobile">
                      We develop and deploy your low-code applications faster
                      (with complete documentation) to accelerate your
                      time-to-value.
                    </p>
                  </div>
                </div>
              </div>
              <input
                type="radio"
                class="tabs__radio"
                name="tabs-example"
                id="tab2"
              />
              <label
                for="tab2"
                class="tabs__label mx-auto"
                style={{ width: "30%", textAlign: "center" }}
              >
                Work Reimagined
              </label>
              <div class="tabs__content">
                <div
                  className="row align-items-center flex-column-reverse flex-lg-row flex-md-row mt-3 mb-5"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
                >
                  <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 mt-5 text-center mb-lg-0 mb-md-0 mb-5 pl-md-5">
                    <h3 className="text-left-mobile">Work Reimagined</h3>
                    <p className="my-4 text-left-mobile">
                      We evaluate your business operations and suggest new
                      workflows and applications that increase efficiency (with
                      and without technology).
                    </p>
                  </div>
                  <div className="col-lg-6 col-md-6 my-lg-4 my-md-4 my-0 mt-lg-0 mt-md-0 mt-5">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/c040157a02b257935a70d16c34e26febb90c0fa4-3024x4032.jpg"
                      alt="Work Reimagined"
                      layout="constrained"
                      style={{ height: "20rem" }}
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                </div>
              </div>
              <input
                type="radio"
                class="tabs__radio"
                name="tabs-example"
                id="tab3"
              />
              <label
                for="tab3"
                class="tabs__label mx-auto"
                style={{ width: "30%", textAlign: "center" }}
              >
                Connect Your Stack
              </label>
              <div class="tabs__content">
                <div
                  className="row align-items-center mt-3 mb-5"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
                >
                  <div className="col-lg-6 col-md-6 mt-4 mb-4">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/f8585207d4841150a0d2b64bbdb1c3657d682ce3-6000x4000.jpg"
                      alt=" Connect Your Stack"
                      layout="constrained"
                      style={{ height: "20rem" }}
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 text-lg-left text-md-left text-center align-self-center mb-lg-0 mb-md-0 mb-5 pl-md-5">
                    <h3 className="text-left-mobile">Connect Your Stack</h3>
                    <p className="my-4 text-left-mobile">
                      We connect your existing systems and build solutions to
                      fill gaps, eliminating silos and enabling smooth data flow
                      across all departments.
                    </p>
                  </div>
                </div>
              </div>
              <input
                type="radio"
                class="tabs__radio"
                name="tabs-example"
                id="tab4"
              />
              <label
                for="tab4"
                class="tabs__label mx-auto"
                style={{ width: "30%", textAlign: "center" }}
              >
                Ongoing Improvements
              </label>
              <div class="tabs__content">
                <div
                  className="row align-items-center mt-3 mb-5"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
                >
                  <div className="col-lg-6 col-md-6 mt-4 mb-4">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/174034a054b146de5125a64b0270e3c53bc5a824-3510x5260.jpg"
                      alt=" Ongoing Improvements"
                      layout="constrained"
                      style={{ height: "20rem" }}
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 text-lg-left text-md-left text-center align-self-center mb-lg-0 mb-md-0 mb-5 pl-md-5">
                    <h3 className="text-left-mobile">Ongoing Improvements</h3>
                    <p className="my-4 text-left-mobile">
                      We monitor performance, refine workflows, and scale
                      solutions as your needs grow—so you don’t get stuck with
                      dated solutions.
                    </p>
                  </div>
                </div>
              </div>
              <input
                type="radio"
                class="tabs__radio"
                name="tabs-example"
                id="tab5"
              />
              <label
                for="tab5"
                class="tabs__label mx-auto"
                style={{ width: "30%", textAlign: "center" }}
              >
                Empowering Your Team
              </label>
              <div class="tabs__content">
                <div
                  className="row align-items-center mt-3 mb-5"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
                >
                  <div className="col-lg-6 col-md-6 mt-4 mb-4">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/1e588be3912a2e7641287e809a79aac182525b8b-2640x3960.jpg"
                      alt=" Empowering Your Team
"
                      layout="constrained"
                      style={{ height: "20rem" }}
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 text-lg-left text-md-left text-center align-self-center mb-lg-0 mb-md-0 mb-5 pl-md-5">
                    <h3 className="text-left-mobile">Empowering Your Team</h3>
                    <p className="my-4 text-left-mobile">
                      Get best practices, hands-on expertise, and industry
                      knowledge delivered straight to your team, making it
                      easier for them to build and manage solutions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </LazyLoadComponent>
        ) : (
          <div className="mobile-swiper d-flex flex-column pt-2">
            <LazyLoadComponent>
              <div className="pt-md-5 pb-md-5">
                <div>
                  <div className="col-12 text-center">
                    <h3 className="">Live in Weeks</h3>
                    <p className="my-4 ">
                      We develop and deploy your low-code applications faster
                      (with complete documentation) to accelerate your
                      time-to-value.
                    </p>
                  </div>
                  <div className="col-12">
                    <img
                      placeholder="blurred"
                      className="rounded d-flex w-100 img-fluid"
                      src="https://cdn.sanity.io/images/55lcecww/production/9689891d27376c47bfe1c37636405b14a1f08b22-6720x4480.jpg"
                      alt="Live in Weeks"
                      layout="constrained"
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                </div>
              </div>
              <div className="pt-md-5 pb-md-5">
                <div>
                  <div className="col-12 text-center">
                    <h3 className="">Work Reimagined</h3>
                    <p className="my-4 ">
                      We evaluate your business operations and suggest new
                      workflows and applications that increase efficiency (with
                      and without technology).
                    </p>
                  </div>
                  <div className="col-12">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/c040157a02b257935a70d16c34e26febb90c0fa4-3024x4032.jpg"
                      alt="Work Reimagined"
                      layout="constrained"
                      style={{ height: "20rem" }}
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                </div>
              </div>
              <div className="pt-md-5 pb-md-5">
                <div>
                  <div className="col-12 text-center">
                    <h3 className="">Connect Your Stack</h3>
                    <p className="my-4 ">
                      We connect your existing systems and build solutions to
                      fill gaps, eliminating silos and enabling smooth data flow
                      across all departments.
                    </p>
                  </div>
                  <div className="col-12">
                    <img
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/f8585207d4841150a0d2b64bbdb1c3657d682ce3-6000x4000.jpg"
                      alt=" Connect Your Stack"
                      layout="constrained"
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                </div>
              </div>
              <div className="pt-md-5 pb-md-5">
                <div>
                  <div className="col-12 text-center">
                    <h3 className="">Ongoing Improvements</h3>
                    <p className="my-4 ">
                      We monitor performance, refine workflows, and scale
                      solutions as your needs grow—so you don’t get stuck with
                      dated solutions.
                    </p>
                  </div>
                  <div className="col-12">
                    <img
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/174034a054b146de5125a64b0270e3c53bc5a824-3510x5260.jpg"
                      alt="Ongoing Improvements"
                      layout="constrained"
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                </div>
              </div>
              <div className="pt-md-5 pb-md-5">
                <div>
                  <div className="col-12 text-center">
                    <h3 className="">Empowering Your Team</h3>
                    <p className="my-4 ">
                      Get best practices, hands-on expertise, and industry
                      knowledge delivered straight to your team, making it
                      easier for them to build and manage solutions.
                    </p>
                  </div>
                  <div className="col-12">
                    <img
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/1e588be3912a2e7641287e809a79aac182525b8b-2640x3960.jpg"
                      alt="Empowering Your Team"
                      layout="constrained"
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                </div>
              </div>
            </LazyLoadComponent>
          </div>
        )}
      </div>
    </section>
  )
}

export default ItConsulting
