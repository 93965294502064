import React, { useContext } from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { Link, graphql } from "gatsby"
import Banner from "../../components/hero/banner-hometeam.js"

import Hero from "../../components/hero/hero-trackvia.js"
import { StaticImage } from "gatsby-plugin-image"

import ItConsulting from "../../components/multi-tab/trackvia-multi-tab.js"

import PartnerSwiper from "../../components/swiper/swiper-partner.js"

import Testimonials2 from "../../components/swiper/testimonial-swiper.js"

import UnlockEnterprise from "../../components/unlock-enterprise.js"
import Cases from "../../components/cases.js"

import CaseStudySection from "../../components/header/header-casestudy-section.js"

const CaseComponent = ({ dataSrc }) => {
  return (
    <section id="case-studies-container">
      <div id="case-studies" className="bg-case-study py-5">
        <div className="container d-none">
          <div className="intro text-center">
            <div className="row">
              <div className="col-md-10 mx-auto">
                {/* <p
                  className="h5 text-light-blue text-uppercase font-weight-bold m-0"
                  style={{ fontFamily: "Futura,sans-serif" }}
                >
                  Case Studies
                </p>
                <h2 className=" my-2 text-white">
                  We Save Clients 1000s of Hours. Every Year.
                </h2> */}
                {/* <p className="text-white">
                  We helped a national healthcare provider save over 9,000 hours
                  in weekly labor costs by developing a single application...
                </p> */}
              </div>
            </div>
          </div>
        </div>
        {dataSrc && <Cases allSanityCaseStudy={dataSrc} />}
        <div className="text-center">
          <Link
            className="btn btn-primary btn-arrow mr-2 mt-4 text-center"
            to="/case-studies/"
          >
            More Case Studies
          </Link>
        </div>
      </div>
    </section>
  )
}

const Trackvia = props => {
  const { data } = props
  const siteTitle = props.data.site.siteMetadata.title
  return (
    <Layout location={props.location} title={siteTitle}>
      <Seo
        title="Workato Gold Partners | Quandary Consulting Group"
        description="Looking for a Workato Gold Partner? We help businesses large and small build, test, and deploy custom integrations that drive measurable impact."
      />
      <div id="main">
        <Hero />
        <PartnerSwiper />
        <div id="it-consult" className="it-consult-scroll">
          <ItConsulting />
        </div>
        <Testimonials2 />
        <CaseStudySection />
        <CaseComponent dataSrc={data.allSanityCaseStudy} />
        <Banner />
        <UnlockEnterprise />
        <section
          className="bg-case-study slogan-section"
          style={{
            paddingTop: "5rem",
            paddingBottom: "5rem",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-10 mx-auto text-center">
                <div>
                  <h2 className="text-white">
                    Get Intricate TrackVia Applications Built in Weeks (Without
                    Exhausting Your Team)
                  </h2>
                  <Link
                    to="/contact/"
                    className="btn btn-primary btn-arrow mr-2 mt-4 text-center py-3 text-uppercase"
                    style={{ marginTop: "auto" }}
                  >
                    Let's Chat
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default Trackvia

export const trackviaQuery = graphql`
  query trackviaQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityCaseStudy(sort: { fields: [publishedAt], order: DESC }, limit: 3) {
      edges {
        node {
          title
          publishedAt(formatString: "MMMM DD, YYYY")
          mainImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          bannerImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`
